$primary-color: #f76c6c;
$secondary-color: #f99797;
$warm-color: #ffd64f;
$light_green: #BBC982;
$dark_red: #892422;
$light_sarcelle: #83B2AD;

/*######################## VINCENT ########################*/
// COULEURS SN
$gris-1:#444444;
$gris-2:#333333;
$bleu-1:#94B1AE;
$bleu-2:#83B2AD;
$bleu-3:#678E8A;
$bleu-4:#5F827E;
$bleu-5:#4E6966;
$marron-1:#5F584E;
$marron-2:#C9B8A2;
$marron-3:#443e36;
$marron-4:#d8ccbb;
$marron-5:#a08b70;
$vert-1:#F7F7F2;
$vert-2:#EAEBDE;
$vert-3:#D7D8CC;
$vert-4:#D4DDBF;
$vert-5:#BFCE9E;
$vert-6:#BBC982;
$vert-7:#7DA33D;
$vert-8:#678732;
$vert-9:#4E6626;
$vert-10:#3B4D1D;
$vert-11:#2F3B1C;
$vert-12:#262D1B;
$rouge-1:#B23D30;
$rouge-2:#892422;
$orange-3:#D37D71;
$orange-2:#c8803d;
$orange-1:#c8553d;
$orange-0:#fff091;


/*SCROLLBAR*/
.fc-scroller {
    scrollbar-color: white white !important;
}

//POLICES

@mixin taille-h1($couleur,$graisse){
    font-size: 24px;
    font-weight:$graisse;
    line-height: 28px;
    color:$couleur;
}

@mixin taille-h3($color,$graisse){
    font-size: 20px;
    font-weight:$graisse;
    line-height: 24px;
    color:$color;
}

@mixin taille-h4($couleur){
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    color:$couleur;
}
@mixin taille-h5($color,$graisse){
    font-size: 16px;
    font-weight:$graisse;
    line-height: 20px;
    color:$color;
}
@mixin taille-h6($color,$graisse){
    font-size: 14px;
    font-weight:$graisse;
    line-height: 16px;
    color:$color;
}
@mixin taille-h7($color,$graisse){
    font-size: 15px;
    font-weight:$graisse;
    line-height: 19px;
    color:$color;
}


@mixin legende($color,$graisse){
    font-size: 11px;
    font-weight:$graisse;
    line-height: 13px;
    color:$color;
}

@mixin taille-paragraphe($color,$graisse){
    font-size: 14px;
    font-weight:$graisse;
    line-height: 18px;
    color:$color;
}

@mixin bouton($color,$fond,$size,$bordercol){
    font-size: $size;
    font-weight:300;
    line-height: 18px;
    color:$color!important;
    background-color:$fond;
    border-radius: 4px;
    border:1px solid $bordercol;
    cursor: pointer;
}

.bouton-1{
    @include bouton($white,$bleu-2,13px,transparent);
    padding:3px 6px 3px 6px;
    	&:hover {
		background-color: $bleu-3;
		@include transition (all .2s);
        }
}

.bouton-liste-attente{
    @include bouton($white,$marron-5,13px,transparent);
    padding:3px 6px 3px 6px;
    	&:hover {
		background-color: $marron-1;
		@include transition (all .2s);
        }
}

.bouton-accepter{
    @include bouton($white,$vert-7,13px,transparent);
    padding:3px 6px 3px 6px;
    	&:hover {
		background-color: $vert-8;
		@include transition (all .2s);
        }
}
.bouton-refuser{
    @include bouton($white,$rouge-1,13px,transparent);
    padding:3px 6px 3px 6px;
    	&:hover {
		background-color: $rouge-2;
		@include transition (all .2s);
        }
}
.bouton-annuler{
    @include bouton($white,$rouge-1,13px,transparent);
    padding:3px 6px 3px 6px;
    	&:hover {
		background-color: $rouge-2;
		@include transition (all .2s);
        }
}
.bouton-modifier{
    @include bouton($white,$rouge-1,13px,transparent);
    padding:3px 6px 3px 6px;
    	&:hover {
		background-color: $rouge-2;
		@include transition (all .2s);
        }
}

.bouton-full{
    @include bouton($white,$orange-3,13px,transparent);
    padding:3px 6px 3px 6px;
    	&:hover {
        cursor: initial;
        }
}
.bouton-waitinglist{
    @include bouton($white,$marron-2,13px,transparent);
    padding:3px 6px 3px 6px;
    	&:hover {
         background-color: $marron-5;
		@include transition (all .2s);
        }
}

.bold{
    font-weight: bold;
}


p
{
    @include taille-paragraphe($gris-1,300); 
}

h4 {
    @include taille-h4($gris-1);
    }

h5 {
    @include taille-h5(white,300);
    }


.titre-page {
    @include taille-h1($gris-1,300);  
    padding-bottom: 40px;
}
.titre-structure {
    @include taille-h1($vert-7,300);  
    padding-bottom: 40px;
}


.defaultimage {
    background: no-repeat url("/build/images/default-asso-img.png");
    min-height: 225px;
    max-width: 225px;
    filter: opacity(60%);
    padding-top: 110px;
    color: white;
    text-align: center;
    width: 225px;
}


.sous-titre{
    @include taille-h3($white,400);
    padding: 5px 5px 5px 5px;
    margin-left: -20px;
    margin-top: 15px;
    margin-bottom: 5px;
    background-color: $vert-7;
}


.aveclimite
    {
    color: $rouge-1;
    padding-left: 5px;
    font-weight:400;
    }
.sanslimite
    {
    color: $vert-7;  
    padding-left: 5px;  
    }

#exergue-vert {
    font-weight: 500;
    color: #7DA33D; }
      
#exergue-rouge {
    font-weight: 500;
    color: #892422; }
      
/*outline des champs actif de formulaire*/
.form-control:focus, .select2-container--default .select2-selection--single[aria-expanded="true"] {
    border-color: #7DA33D !important;
    box-shadow: 0 0 5px #7DA33D !important;}

.pointer {
    cursor: pointer; }
      
/*reduction de mot par... mobile*/
@media only screen and (max-width: 768px) {
.troispetitspoints {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 20px; } }
   
/*Supprimer la bande blanche des popup sur la carte*/
.gm-style-iw-ch {
    padding-top: 0px!important;
    }