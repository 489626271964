.formEvent > * {
    padding: 0vw;
}

.formEvent p{
    @include taille-paragraphe($gris-1,300);  
}

.formEventDates {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.formLatLng {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.validFilterBack {
    background-color: #83B2AD !important;
    border: none;
}

.firstFilterEvent {
    display: flex;
    align-items: center;
    width: auto;
    max-width: 100%;
}

.selectMenuSecond {
    width: 100%;
    max-width: 40%;
}
/*
.toFlex {
    margin:0 !important;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
*/

.secondFilterEvent {
    width: auto;
    max-width: 60%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.table {
    background-color: #ffffff;
}

.event-legende {
    color: #4E6626;
}

.event-legende a {
    color: darkred;
}
.event-legende li {
    @include taille-paragraphe($bleu-3,300);  
}



.button-flex {
    display: flex;
    justify-content: space-evenly;
}

.btn-validate {
    background:  #83B2AD;
    color: white;
}
/*
.logoListItems {
    width:40px;
    padding: 10px 5px 0 5px;
}
*/

/* debut Report */
.containeReport {
    background-color: #bfce9e;
    padding: 2vw;
}

.containerReportShow {
    background: #f4f4ed;
}

.cardReportShow {
    display: inline-block;
    text-align: center;
}

.imgReport {
    max-width: 100%;
}
/* fin Report */

/*################################ VINCE #####################################*/
/*LISTE */
.logoListItems {
    max-width:20px;
    margin:10px 0px 0px 0px;
}
.spacer-liste{
    margin-bottom: 20px;
    border-radius: 4px;
}

.info-liste-sup{
    padding: 0;
    margin: 0;
}

.zone-categorie{
    flex-basis: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    border-radius: 4px 0 0 4px;
}

.overflow-hidden{
    overflow: hidden;
}

.zone-cat:nth-child(1) {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 80%;
    flex-direction: column;
    align-items: center;
}

.zone-cat:nth-child(2) {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 20%;
    flex-direction: column;
    align-items: center;
}


.zone-categorie-map {
    flex-basis: 40px;
    border-radius: 3px 0 0 3px;
    width: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

@media (min-width: 768px){
.cover{
    flex: 33.33333%;
    max-width: 40%;
}
}

@media (max-width: 768px){
.cover{
    width: 100%;
}
}
.cover-image {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        }
    cursor: pointer;
    }
.cover-image-canceled {
    position: absolute;
    font-family: 'Black Ops One', cursive;
    // color: rgba($white, 0.95);
    color: rgba($white, 0.80); // by VC
    border: 0px solid $rouge-1;
    background: linear-gradient(to right, rgba($rouge-1, 0.9),rgba($rouge-1, 0.6), rgba($rouge-1, 0.8), rgba($rouge-1, 1));
    padding: 3px 7px 3px 7px;
    margin: 10px 0 0 5px;
    -webkit-transform: rotate(-8deg);
    transform: rotate(-8deg);
    font-size: 18px;
}


.corps-liste {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 5px 10px 10px 20px;
    }

.resume {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 5px;
    word-break: break-word;
    }

.resume1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-bottom: 5px;
    word-break: break-word;
    } 


.firstBottomEvent {
        margin-top:5px;
        line-height: 14px;
    }

.eventCard {
        max-height: 176px;
        overflow: hidden;
}

/*###########BACKOFFICE TABLEAU###############*/

.bodyback {
    display: flex;
    flex-direction: row;
    flex-basis:100%;
    }

.titreback{
    display: flex;
    flex-wrap: wrap;
}
.compteurback{
    display: flex;
    flex-wrap: wrap;
}

/*bloc des titre principaux*/
.headContent {
    margin-bottom: 20px;
}
.select2-container{
        margin: 5px 5px 0 0;
        position: relative;
}


/*le bloc des filtres du haut*/
.toFlex {
    margin:0 !important;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 10px 0px 0px 45px;
    background-color: $vert-5;
}
/*1ere ligne du tableau (titres)*/
.headTable {
    border: 0px!important;
    background-color: $vert-4;
    }
/* interieur 1er ligne du tableau*/
.table thead th {
    vertical-align: middle;
    border-bottom: 0px solid #dee2e6;
    height: 30px;
    font-weight: 400;
    background-color: #ffffff;
}

/*pictogramme oeil apercu*/
.icon-apercu-event{
    min-width: 24px;
    margin: 5px;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color:$vert-1;
}
/*colonne du tableau*/
.table-striped tbody td{
    vertical-align: middle;
}
/*espacement 1ere colonne du tableau*/
.table-striped tbody td:first-child{
    padding-left:20px!important;
    vertical-align: middle;
}

.table-newsinscription tbody, .table-newsinscription tr {
    background-color:white;
    border-top: 0px solid $vert-4;
    text-align:center;
}

/*espacement 1ere colonne du tableau*/
.table-newsinscription tbody td:first-child{
    padding-left:20px!important;

}

.table-newsinscription tbody td:last-child{
    padding-right:10px!important;
    text-align:right;
}
.table-newsinscription tbody td:nth-of-type(2), .table-newsinscription tbody td:nth-of-type(3), .table-newsinscription tbody td:nth-of-type(4){
    text-align:center;

}
@media only screen and (max-width: 900px) {
.table-newsinscription tbody td:nth-of-type(1), .table-newsinscription tbody td:nth-of-type(2), .table-newsinscription tbody td:nth-of-type(3), .table-newsinscription tbody td:nth-of-type(4){
    width:auto;
}
}
@media only screen and (min-width:901px) and (max-width:5120px) {
    .table-newsinscription tbody td:nth-of-type(1), .table-newsinscription tbody td:nth-of-type(2), .table-newsinscription tbody td:nth-of-type(3), .table-newsinscription tbody td:nth-of-type(4){
        width:145px;
    }
}



.table-striped2 tbody tr{
    background-color:white ;
}

.table-striped2 tbody tr:nth-child(2n) {
    background-color:$vert-1;
}
/*
.table-striped2 tbody tr:nth-child(4n+1) {
    background-color:$vert-1;
    padding-left:20px!important;
}
.table-striped2 tbody tr:nth-child(4n+3) {
    background-color:white ;
}
*/


.table-striped2 tbody td:first-child{
    padding-left:20px!important;
}

.table th, .table td {
    padding: 6px 4px 6px 0px;
    vertical-align: top;
    border-top: 0px solid #dee2e6;
    @include taille-paragraphe($gris-2,300);
    a {
        color:$rouge-1;
    }
}

/*espacement 1ere ligne, 1ere colonne du tableau*/
.table thead th:first-child{
    padding-left:20px!important;
}


// div.tableEvent table{
//     width:100% !important;
// }
/*largeurs des colonnes du tableau*/
// div.tableEvent {
//     .table-striped th:nth-child(1) {
//         width: 40px;
//     }
//     .table-striped th:nth-child(2) {
//         width: 32%;
//     }
//     .table-striped th:nth-child(3) {
//         width: 13%;
//     }
//     .table-striped th:nth-child(4) {
//         width: 6%;
//     }
//     .table-striped th:nth-child(5) {
//         width: 10%;
//     }
//     .table-striped th:nth-child(6) {
//         width: 5%;
//     }
//     .table-striped th:nth-child(7) {
//         width: 13%;
//     }
//     .table-striped th:nth-child(8) {
//         width: 5%;
//     }
//     .table-striped th:nth-child(9) {
//         width: 8%;
//     }
//     .table-striped th:nth-child(10) {
//         width: 3%;
//     }
// }

@media only screen and (max-width: 770px) {
    .zone-categorie{
        flex-basis: 100%;
        border-radius: 4px 4px 0 0;
        height:35px;
        }
    .logoListItems {
        max-width:none;
        max-height: 20px;
        margin:5px 5px;
        }
    .zone-cat:nth-child(1) {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: flex-start;
        }

    .cover-image {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        }
    .cover-image img {
    object-fit: cover;
    height: 150px;
        }

    .cover-image-map{
        display: none;
        }
        
    .eventCard {
        min-height: 25vh;
        max-height: 450px;
        }

    .eventInfosBottom {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-content: center;
        flex-direction: column;
        }
    .corps-liste {
            padding: 5px 15px 15px 15px;
        }
    .secondBottomEvent {
        padding-top: 20px;
        }
}

/*############LISTE DES EVENEMENT AVEC FILTRES######################*/
/*zone active dans le-a ligne des resultats en haut*/
select.pgSlc {
    height: 35px;
    margin: 0;
    border: 0!important;
    background-color:$vert-8!important;
    vertical-align: middle;
    color:$white!important;
}
/*resultat par page*/
select.rspg {
    height: 35px;
    margin: 0;
    border: 0!important;
    background-color:$vert-8!important;
    margin: 0 0 0 5px;
    vertical-align: middle;
    color:$white!important;
}

#date_filter_start{
    margin-top:17px;
    border:0px;
    height:25px;
    padding-left:4px;
    background-color:$vert-1;
    font-weight:300;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
#date_filter_end{
    margin-bottom:17px;
    border:0px;
    height:25px;
    background-color:$vert-1;
    font-weight:300;
    padding-left:4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

/*ligne des resultats*/
.inf {
    clear: both;
    width: auto;
    height: 35px;
    min-width: 400px;
    background-color: $vert-5!important;
    font-size: inherit;
    margin: 0;
    padding: 0;
    border :0px solid #ccc!important;
    overflow: hidden;
    border-bottom-left-radius: 4px!important;
    border-bottom-right-radius: 4px!important;
    border-top-left-radius: 0px!important;
    border-top-right-radius: 0px!important;
    font-size:14px!important;  
}

/*ligne des filtres*/
.fltrow {
    background-color:$vert-5!important;
    border-top-left-radius: 4px!important;
    border-top-right-radius: 4px!important;
    td {
        border:0px !important;
    }
}
/*marche pas ?...*/
#evenements-table{
    border-top-left-radius: 4px!important;
    border-top-right-radius: 4px!important;
}

#evenements-table td:nth-child(4){
    background-color:none;
}

/*recherche mot clef*/
.flt, .flt_s, .single_flt {
    background-color:$vert-1!important;
    border: 0px!important;
    margin-top: 17px!important;
    border-radius: 4px!important;
}



/*pas actif car original en > important*/
.fltrow td {
    border-bottom: 0px!important;
    padding: 0em !important;
}

/*ligne des intitules*/
table.TF th {
    background-color:$vert-4!important;
    border:0px!important;
}

table.TF td {
    border-bottom: 0px!important;
}

#gmap-search{
    margin-bottom: 10px;
}

.map-infowindow{
    padding: 10px;
}

.map-infowindow button{
    margin-top: 5px;
}

#gmap-map{
    margin-bottom: 20px;
}

/*
.select2-selection__rendered span{
    display: block;
    padding-left: 8px;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: 4px solid #000000;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: red;
    line-height: 28px;
    border: 4px solid #000000;
}
*/

.ui-helper-hidden-accessible {
    display:none;
}
