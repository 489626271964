.registersupprimer {
    float: right;
    border: solid 1px #8A2822;
    background-color: #8A2822;
    color: whitesmoke;
    margin: 5vh 20vh 2vh 51vh;
    border-radius: 0.5vh;
    cursor: pointer;
    padding: 1vh 3vh 1vh 3vh;
}

.registersupprimer:hover {
    background-color: #bd2d24;
}


.col-6 {
    margin-left: -1.9vh;
}

.col-9 {
    max-width: 100%;
}

.agreeregister {
    margin-top: 5vh;
}



.btnannuleregister {
    border: solid 1px #83B2AD;
    background-color: #83B2AD;
    color: whitesmoke;
    border-radius: 0.5vh;
    cursor: pointer;
    padding: 1vh 3vh 1vh 3vh;
}


.btnannuleregister:hover {
    background-color: #67ab79;
}


/*######################## VINCENT #########################*/
/*REA DE COMPTE*/
.bigregister {
    background-color:$vert-2;
    padding: 0 10vw 0 10vw;
    flex:100%

}

.register {

    background-color:$vert-1;
    border-radius: 4px;
    /*width: 70%;*/
    margin: 24px auto 0px auto;
    /*max-width: 1024px;*/
    padding: 25px 65px 25px 65px;
    label {
    @include taille-paragraphe($gris-1,300);
    }
    margin-bottom: 30px;
}

@media (max-width: 1920px){
.background-reason {
    background-image: url(/build/images/no-result-inscription.svg);
    background-position: 50% 80px;
    background-size: 60%;
    background-repeat: no-repeat;
    height: 50vh;
    color: rgba($vert-8, 1);
}
}

@media (min-width: 1920px){
    .background-reason {
        background-image: url(/build/images/no-result-inscription.svg);
        background-position: 50% 80px;
        background-size: 40%;
        background-repeat: no-repeat;
        min-height: 40vh;
        color: rgba($vert-8, 1);
    }
}


div.crossed-out {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAABCAYAAADjAO9DAAAAFElEQVQIW2NkYGD4D8QgwAilUfgAKjICAn16QBUAAAAASUVORK5CYII=');
    background-repeat: repeat-x;
    background-position: 50% 1em;
    background-position-x: 50%;
    background-position-y: 0.5em;
}

.waiting-list td {
        background:$marron-4;
}

.buttonregister {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.btnannuleregister {
    @include bouton($white,$bleu-3,14px,transparent);
    padding:5px 20px 5px 20px;
    	&:hover {
		background-color: $bleu-2;
		@include transition (all .2s);
        }
        margin-left:4px;
}

.btnvalideregister {
    @include bouton($white,$rouge-2,14px,transparent);
    padding:5px 20px 5px 20px;
    	&:hover {
		background-color: $rouge-1;
		@include transition (all .2s);
        }
        margin-right:4px;
}

.laley {
    margin-top: 20px ;
    @include legende($gris-1,300);
    text-align: justify;
}

.titreregister {
    /*width: 100%;*/
    margin: 0px auto 0px auto;
    /*max-width: 1024px;*/
    padding-top: 40px;
    @include taille-h1($gris-1,300);
}

.registermail {
    margin: 0;
}

/*conditions d'utiliations*/
.registerint {
    color:$bleu-3;
    p {
        text-align: justify;
    } 
    
}


.registermdp {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
}

.registername {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
}

.zipregister {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
}

.form-group {
    flex-basis: 48%;
    margin-bottom: 0px!important;
}
.adressgroup {
    flex-basis: 100%;
    margin-bottom: 10px!important;
}

/*style des champ*/
.form-control, .select2-container--default .select2-selection--single {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5;
    color:$vert-8;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid $vert-3;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 10px
}


textarea.form-control {
    min-height: 150px!important;
}

/*couleur txt champ form edit event*/
.select2-selection__rendered{
    color:$vert-8!important;
    padding-left: 0px!important;
}
/*survol listing*/
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: $bleu-3!important;
    color: white;
}
/*activ listing*/
.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: white;
}

.connecregister {
    @include taille-h5($gris-1,500);
}

.infocontactreg {
    @include taille-h5($gris-1,500);
    margin:  2vh 0 2vh 0;
}

.registeradress  {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
}

/*########## MEDIA #############*/
@media only screen and (max-width: 991px) {
.register {
    /*width: 80%;*/
    margin: 24px auto 40px auto;
    padding: 15px 50px 15px 50px;
    }

.titreregister {
    padding-top: 40px;
    margin: auto;
    }
}

@media only screen and (max-width: 650px) {

    .registermdp, .registername, .zipregister {
        flex-direction: column;
        }
    
    .register {
        width: 100%;
        margin: 24px auto 20px auto;
        /*max-width: 1024px;*/
        padding: 15px 20px 15px 20px;
        }
    
    .titreregister {
        padding-top: 20px;
        margin: auto;
        }

    .bigregister {
        padding: 0 2vw 0 2vw;
    }
    }