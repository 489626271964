/*##### boutton #####*/
.btnContactStruc {
    margin: 0 0 1vw 0;
    float: right;
    background-color: $light_green;
    color: white;

    &:hover {
        background-color: darken($light_green, 15);
        color: white;
        text-decoration: none;
    }
}

.btnCancel {
    background-color: $dark_red;
    color: white;

    &:hover {
        background-color: lighten($dark_red, 15);
        color: white;
    }
}

.btnCreate {
    background-color: $light_sarcelle;
    margin-bottom:10px;
    color: white;
    &:hover {
        background-color: darken($light_sarcelle, 15);
        color: white;
    }
}

/*##### fin boutton #####*/

.titleNewEditStruc {
    margin: 0 0 2.5vw 0;
    padding: 0;
}

.blockFormStruc {
    background: $vert-4;
    border-radius: 4px;
    /*width: 70%;*/
    margin: 24px auto 30px auto;
    /*max-width: 1024px;*/
    padding: 25px 65px 25px 65px;
    label {
        @include taille-paragraphe($gris-1,300);
        }
}

.structure-address{
    padding-left: 3px;
    border-left: 3px solid gray;
}