.FilterMenuTitleblock{
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    &.categories{
        .FilterMenublock img {
            max-width: 16px;
            margin: 0 5px 0 15px;
        }
    }
}

.FilterMenuTitle {
    background-color: $gris-1 !important;
    height: 41px;
    padding: 11px 0px 29px 15px;
    h5 {
        &.highlighted span.filter-title{
            color:$orange-0!important;
            opacity: 1;
        }
        span.filter-title {
        color:white;
        opacity: 0.6;
        }
    }
    a:hover{
        text-decoration: none;
        background-color: $marron-1 !important;
    }
    cursor: pointer;
}

.FilterMenuTitle h5.highlighted {
    opacity: 1;
}

.FilterMenublock {
    display: none; //hide on page load FK:DISABLE
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0px;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    color: $gris-1;
    input[type="radio"], input[type="checkbox"]{
        box-sizing: border-box;
        margin: 3px 8px 0 15px;
    }
    label{
        cursor:pointer;
        margin-bottom: 0;
    }
    // display: block;
    // position: absolute; FK:DISABLE
    z-index: 9999;
    background: white;
    width: 100%;
    ;
}

.tousaucun button{
    font-weight: 400;
    padding:0px 10px 0px 8px;
    width: 100%;
    border-radius: 11px!important;
    margin:0 15px 0 15px;
    @include bouton($white,$vert-7,12px,transparent);
        &:hover {
        background-color: $vert-8;
        @include transition (all .2s);
        text-decoration: none;
        }
}


.FilterMenuSeparateur{
    margin-top: 0;
    margin-bottom: 0;
    border: 0;
    border-top: 1px dashed $vert-3;
}

.FilterMenuStructureName {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    line-height: 90%;
    }



.filterMenu {
    .filterDiv {
        height: 35px !important;
        width: 100% !important;
        display: inline-flex;
        padding: 0px;
        align-items: center;
        cursor: pointer;
    &:hover{
        color:$vert-7;
    }
    &:current{
        color:$vert-7;
    }
    &:not(.tousaucun) {
        padding-right: 20px;
    }
    }
}

.structures {
    .filterDiv:not(.tousaucun) {
        height: auto !important;
        padding-top: 4px;
        padding-bottom: 4px;
    }
}

.icon-favParticipation{
    min-width: 13px;
    margin-right: 5px;
    margin-top: -4px;
}

.iconArrow {
    width: 8px;
    transform: rotate(-90deg);
    transition: all 0.4s ease;
}

.iconArrow.rotated {
    width: 8px;
    transform: rotate(0deg);
}

.iconArrow {
    filter: invert(100%);
    margin: -3px 8px 0 0;
}

.filter_item_themes_checkbox {
    width: 14px;
    height: 14px;
    margin: 5px;
    border-radius: 2px;
    &.unchecked{
    opacity: 0.12;
    }
}

.resetbouton{
    display: inline-block;
    font-weight: 400;
    text-align: left;
    vertical-align: middle;
    padding: 10px 0 10px 12px;
    width: 100%;
    border-radius: 0 0 4px 4px!important;
    @include bouton($white,$bleu-4,17px,transparent);
        &:hover {
        background-color: $bleu-5;
        @include transition (all .2s);
        text-decoration: none;
        }
}
.reseticon{
    max-width: 16px;
    margin: 0 5px 2px 0px;

}

.FilterCheckbox{
    max-width: 20px;
    margin: 0 10px 2px 15px;
}

/*########Dates#########*/
.date-block{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    padding: 10px 0 15px 15px;
}

.date-col{
    max-width: 90%;
    display: flex;
    align-items: center;
    .form-control{
        background-color: $vert-2;
        border:none;
    }
}

.date-espace{
    padding-right:5px;
}

#structures_organisatrices{
    max-height: 200px;
    overflow: auto;
}

.webinaires {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    
    .slider {
        color: white !important;
        display: flex;
        gap: 12px;
        flex-direction: row;
        height: 20px;
        background-color:$gris-2;
        border: none;
        border-radius: 4px;
        align-items: center;
        font-size: 12px;
        padding: 2px;
        div{
            width: 16px;
            text-align: center;
            line-height: 15px;
            height: 16px;
            img{
                width: 8px;
                padding-bottom: 2px;
            }
            &.yes{
                font-size: 180%;
            }
        &:hover{    
            background-color:rgba(white, 0.5);
            border-radius: 4px;
        }
                        
        &.checked{
            border-radius: 4px;
            
            &.yes{
                background-color:#444444;
                
            }
            &.only{
                background-color: #678732;
            }
            &.no{
                background-color: #b35539;
            }
        }
            
        }
    }
    
}
.icon-webinar {
    width: 14px;
    margin-left: 0px;
    margin-top: 0px;
}
