footer {
    width: 100%;
}

.footer-top {
    background-color: #444444;
    border-bottom: 5px solid $vert-8;
    text-align: center;
    color: rgba($white, 0.6);
    font-size: 13px;
    font-weight: 300;
    padding: 10px 0 10px 0;
}

.footer-top img {
    margin: 8px 0 8px 15px;
    width: 80px;
    vertical-align: middle;
}

.footer-bottom {
    background-color: #7DA33D;
    height: 20vh;
}

.footer-top a {
    color: rgba($white, 0.6);
}

.footer-top a:hover {
    color: white;
    text-decoration: none;
}

