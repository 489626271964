.ui-autocomplete{
    background: white;
    width: 70%;
    margin: 10px!important;
    font-weight: 300;
}

.ui-menu-item{
    padding:8px;
 }

.ui-menu-item:hover{
    background:$vert-4;
    cursor:pointer;
}
