
@media only screen and (min-width:1501px) and (max-width:5120px) {
.bigquoi {
    background-color:$vert-2;
    padding: 0 20vw 0 20vw;
    a {text-decoration: none !important;
        color: $vert-8;}
}
}
@media only screen and (max-width:1501px) {
    .bigquoi {
        background-color:$vert-2;
        padding: 0 15vw 0 15vw;
        a {text-decoration: none !important;
            color: $vert-8;}
    }
}

/*.quoiquoi {
    margin-top: 10vh;
    display: flex;
    justify-content: space-between;
}
*/

.titrequoi {
    margin: 0px auto 0px auto;
    padding-top: 40px;
    @include taille-h1($vert-8,300);
}


.soustitrequoi {
    margin: 0px auto 0px auto;
    padding: 20px 0 10px 0;
    @include taille-h5($orange-1,500);
}

.supprimerquoi {
    float: right;
    border: solid 1px #8A2822;
    background-color: #8A2822;
    color: whitesmoke;
    margin: 5vh 13vh 2vh 51vh;
    border-radius: 0.5vh;
    cursor: pointer;
    padding: 1vh 3vh 1vh 3vh;
}

.supprimerquoi:hover {
    background-color: #bd2d24;
}

.txtquoi{
    background-color: whitesmoke;
    margin: 24px auto 40px auto;
    padding: 3vh 7vh 3vh 7vh;
    border: none;
    border-radius: 0.5vh;
    text-align: justify;
    li {
        @include taille-paragraphe($gris-1,300); 
        padding: 0 0 5px 40px;
    }
    a {
        background-color: $vert-2;

    }
}


/*.register {
    margin: 24px auto 40px auto;
    padding: 15px 50px 15px 50px;
    }
*/

.structurequoi {
    margin: 0px auto 0px auto;
    padding-top: 40px;
    @include taille-h1($vert-8,300);
}

.listestructure {
    margin: 0vh;
}

.listestructure h5 {
    color: black;
}

.card-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -30px; 
    margin-bottom:60px;
}


.lienvert {
    text-decoration: none !important;
    color: #83B2AD;
}


.lienvert:hover {
    text-decoration: none !important;
    color: darkseagreen;
}

@media only screen and (min-width:2001px) and (max-width:5120px) {
    .cardSN {
        margin-right: 0px;
        width: calc(25% - 30px);
        margin-left: 30px;
        margin-top: 40px;
    }
} 



@media only screen and (min-width:1351px) and (max-width:2000px) {
    .cardSN {
        margin-right: 0px;
        width: calc(33.33% - 30px);
        margin-left: 30px;
        margin-top: 40px;
    }
}

@media only screen and (max-width: 1350px) {
    .cardSN {
        width: calc(50% - 30px);
        margin-left: 30px;
        margin-top: 40px;
    }
}

@media only screen and (max-width: 900px) {
    .cardSN {
        width: calc(100%);
        margin-left: 30px;
        margin-top: 40px;
    }

    .txtquoi{
        padding: 1vh 1vh 1vh 1vh;
    }
    .bigquoi {
        padding: 0 4vw 0 4vw;
    }
}

@media only screen and (min-width: 1680px) {
    .txtquoi {
    column-count: 2;
    column-gap: 5em;
    }
}    

