.inscrirehover {
    text-decoration: none !important;
    color: #83B2AD;
}

.inscrirehover:hover {
    text-decoration: none !important;
    color: #67ab79 !important;
}


.remember {
    margin-left: 4vh;
}

.btnlogin {
    background-color: #83B2AD !important;
    border: none!important;
}

.btnlogin:hover {
    background-color: #67ab79 !important;
}


/*############### VINCENT ####################*/
.bodylogin {
    background-color: white !important;
    min-height:100%;
    a{
        color: $bleu-3;
    }
    label{
        margin-right:10px;
    }
}

.logologin {
    width: 11vh;
    margin: 4vh 10vh 5vh 5vh;
    text-align: left;
}
.connecter {
    color: $gris-1;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction:column;
}

.form-log {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction:column;
    align-items:center;
    margin: 20px;
}
.loginborder {
    border: solid 1px $bleu-1;
    border-radius: 4px;
    justify-content: center;
    padding: 30px;
    @include taille-paragraphe($gris-1,300); 
 }

.titrelogin {
    margin: 0 0 10px 0;
    text-align: center;
}

.inscrirelogin {
    margin: 1vh;
    text-align: center;
}

.buttonlogin {
    margin-left: 0;
    margin-bottom: 0;
    margin-top: 0;
    color: whitesmoke;
}

.bouton-2{
    @include bouton($white,$bleu-2,16px,transparent);
    padding:10px 10px 10px 10px;
    	&:hover {
		background-color: $bleu-3;
		@include transition (all .2s);
        }
}

.bouton-rouge{
    @include bouton($white,$rouge-1,16px,transparent);
    padding:10px 10px 10px 10px;
    	&:hover {
		background-color: $rouge-2;
        text-decoration: none;
		@include transition (all .2s);
        }
}

.bouton-vert{
    @include bouton($white,$vert-7,16px,transparent);
    padding:10px 10px 10px 10px;
    	&:hover {
		background-color: $vert-8;
		@include transition (all .2s);
        }
        a{
        text-decoration: none!important;
        }
}

.bouton-bleu{
    @include bouton($white,$bleu-3,16px,transparent);
    padding:10px 10px 10px 10px;
    	&:hover {
		background-color: $bleu-5;
		@include transition (all .2s);
        }
}

.bouton-marron{
    @include bouton($white,$marron-5,16px,transparent);
    padding:10px 10px 10px 10px;
    	&:hover {
		background-color: $marron-1;
		@include transition (all .2s);
        }
}

.copy-text-button{
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid #83B2AD;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.loginmail {
    margin: 0 0 10px 0;
}

.mdplogin {
    margin: 0 0 10px 0;
}
.remember {
    margin-left: 0;
}

.etoile {
    margin: 10px 0 0 0;
    max-width:350px;
    text-align: justify;
   p {
        @include legende($gris-1,300);
     }
}

/*fond de page structure*/@at-root
.jumbotron {
    padding: 40px!important;
    margin-bottom: 2rem;
    background-color:$white;
    border-radius: 0.3rem;
}

@media only screen and (max-width: 991px) {
   
    .bodylogin {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .logologin {
        margin: 4vh 5vh 5vh 5vh;
    }
}


/* logo version mobile */
@media only screen and (max-width: 650px) {
    .logologin {
        width: 20vh;
        margin: 4vh 0vh 2vh 0vh;
        text-align: left;
    }
}