.participateContainer {
    background: white;
    flex: 0 0 50% !important;
    border-radius: 5px;
}

.validateRow {
    background:  #EAEBDE;
}
/*
.participateBtn {
    background: #83B2AD !important;
    color: white !important;
    max-width: 20vw;
}
*/

.participationFormContainer {
    background:none;
    padding-top: 0px !important;
}

.participationFormContainer table {
    line-height: 45px;
}

.participationTable, .participationFormContainer table thead {
    text-align: center;
}

.participationButton {
    background: #eceddc;
    color: #83B2AD;
}

.participationButton tr {
    border-top: 1px solid saddlebrown;
}

.participationButton tr:hover {
    background-color: #c9cd98;
}

.participationNumber {
    max-width: 20% !important;
}

.participateResult {
    display: flex;
    justify-content: space-around;
}

.trash:hover {
        color: $rouge-1;
}


/*#################VINCENT######################*/

/* bouton retour accueil*/
.bout-retour{
    padding: 0 0 20px 0;
}

.participateBtn {
    background: #83B2AD !important;
    color: white !important;
    max-width: 20vw; }

        
.participationTable th, .participationTable td, .participationFormContainer table thead th, .participationFormContainer table thead td {
    padding: 10px 2px;
    vertical-align: top;
    border: 0px; }
      
.participationTable tr {
    background: $vert-2;
    color: $gris-1; 
    }
      
.participationTable tr:nth-child(even) {
    background: $vert-1; }
      
/*1ere ligne tableau*/
  .tab-ligne-vert {
    background: $vert-7;
    color: $white;
    border-radius: 8px; }

.participationButton {
    background: #eceddc;
    color: $vert-8; }
      
.participationButton tr {
    border-top: 1px solid saddlebrown; }
      
.participationButton tr:hover {
    background-color: $vert-8;
    cursor: pointer;
    color: $white }
     
.participationNumber {
    max-width: 20% !important; }

.participateResult {
    display: flex;
    justify-content: flex-end;
    background-color: #4E6626;
    flex-wrap: wrap;
    padding: 20px 10px 0px 20px !important;
    border-radius: 0px 0px 8px 8px; }

    .participateResult p {
    font-size: 18px;
    font-weight: 300;
    line-height: 20px;
    color: #fff; }

.user-row-center {
    display: flex;
    flex-wrap: nowrap;
    margin-right: 0px;
    margin-left: 0px;
    justify-content: center; 
}

.participation-canceled td {
    color:$marron-2;
    background:$vert-2;
}

.register.waiting-list {
    background-color:$marron-4;
    margin-bottom: 10px;
}

.register.primary-list {
    background-color:$vert-4;
    margin-bottom: 10px;
}

span.participations-count{
    font-weight: bold;
}

span.overloaded {
    color: red;
}

div.primary-participations-container table thead th{
    background-color: $vert-4 !important;
}

div.waiting-participations-container table thead th{
    background-color: $marron-4 !important;
}

.total-price{
    text-align: right;
    @include taille-h3($vert-8,500);
}

.number-participant {
    @include taille-paragraphe($marron-1,400); 
    font-style: italic;
}

#participation-as-other {
    justify-content:space-between;
    display: flex;
    div:nth-of-type(1) { flex-basis: 25%; }
    div:nth-of-type(2) { flex-basis: 25%; }
    div:nth-of-type(3) { flex-basis: 50%; }

}

@media only screen and (max-width: 900px) {
#participation-as-other {
    flex-direction: column;
}
}


/*-----4 prix---------*/
/*@media only screen and (max-width: 937px) {

    #container-four-price {
        display: flex;
        flex-direction: column;
        align-items:flex-start;
    }
    #container-two-price {
        justify-content:flex-start;
        display: flex;
        align-items: flex-end;
        div:nth-of-type(1) { flex-basis: 50%; margin-right: 4px; }
        div:nth-of-type(2) { flex-basis: 50%; }
    }
}*/

  
@media only screen and (min-width: 938px) {}

    #container-four-price {
 /*       justify-content:flex-start;*/
        display: flex;
        align-items: flex-end;
        flex-direction: row;
        flex-wrap: wrap;
       /* justify-content: space-between;*/
      /*  max-width: 1000px;*/
    }

@media only screen and (min-width: 938px) {
    .space-price{
        margin-right:50px;
    }
}
@media only screen and (max-width: 937px) {
    .space-price{
        margin-right:0px;
    }
}


    .container-two-price {
        justify-content:flex-start;
/*      flex: auto;*/
        display: flex;
        flex-direction: column;
        align-items: flex-start;
/*      div:nth-of-type(1) {padding-right: 20px;}*/
/*      div:nth-of-type(1) { flex-basis: 40%; margin-right: 10px;}
        div:nth-of-type(2) { flex-basis: 40%; }
        
*/      div.form{
            display: flex;
            flex-direction: row;
            align-items: flex-end;
        }
        div.discount{
            margin-left: 8px;
        }
        label{
            font-weight: bold;
        }
        
    }
    
    .container-comment-price {
        justify-content:flex-start;
        flex: 100%;
        display: flex;
        align-items: flex-end;
        div:nth-of-type(1) { flex-basis: 100%;}
    }




