* {
    padding: 0;
    margin: 0;
}

.content {
    padding: 0!important;
}

.col-3 {
    padding-right: 0 !important;
}

.col-12 {
    padding: 0 !important;
    margin: 0 !important;
}

.buttons {
    margin-left: 15px;
}

.buttonType {
    background-color: #83b2ad;
    border: none;
    padding: 5px 8px;
    margin-right: 5px;
    border-radius: 5px;
    color: #fff;
}

.filterUnderHead {
    margin-right: 3px;
}

.activeFilter {
    color: #678e8a;
}

.container-back {
    background: #f4f4ed;
}

/*##################### VINCENT #####################*/
.headerBack {
    background: $gris-2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    height: 40px;
}

.homeBack {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 30px;
    img {
        width: 16px;
    }
    p {
        padding-top: 18px;
        padding-left: 5px;
        color: $bleu-2;
    }
}

.homeBack p:hover {
    color: white;
    text-decoration: none;
}


.btn-back {
    background: transparent;
    @include taille-paragraphe($bleu-2,300);
}

.btn-back:hover {
    color: white;

}

.principalContent {
    padding-top: 65px;
    background-color:$vert-2;
    flex:1;
    padding: 30px 40px 60px 40px;
}

.dropdown-menu, .dropdown-menu a {
    background: $gris-2;
    @include taille-paragraphe(white,300);
}

.dropdown-menu a:hover {
    background:$bleu-5;
    color: white;
}

.logoSortiesNatureBack {
    max-width: 219px;
    width: 100% !important;
}

.sorties_nature_logo_01 {
    background-color: #ffffff;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.menuIconBack {
    width: 18px;
    margin-right: 8px;
}

.submenuIconBack {
    width: 38px;
    margin-right: 8px;
    padding-left:25px;
}
.highlighted{
    /*font-style: italic;*/
    color:$orange-0!important;
}


.IconTitreBack {
    width: 28px;
    margin-right: 12px;
    padding-bottom:10px;
}

.menuBackOffice {
    text-align: left !important;
    padding-left: 25px !important;
    background-color:$bleu-4 !important;
    border: none !important;
    position: static;
    transition: none;
    @include taille-h7($white,300);
}

.menuBackOffice:hover {
    background-color:$bleu-3!important;
    color:$white;
}


.contactLink {
    margin-top: 50px;
    padding-left: 25px !important;
    background-color:$bleu-4 !important;
    border: none !important;
    position: static;
    transition: none;
    height: 45px;
    background-image: url("/build/images/poser-question.png");
    background-position-y: bottom;
    background-position-x: 25px;
    background-repeat: no-repeat;
    background-size: 200px;
    filter: opacity(0.7);
    transform: translate(0px, 0px);
    transition: filter 0.5s, transform 0.5s;
}
.contactLink:hover {
filter: opacity(1);
transform: translate(0px, -2px);
transition: filter 0.5s, transform 0.5s;
}

.tutoLink {
    margin-top: 5px;
    padding-left: 25px !important;
    background-color:$bleu-4 !important;
    border: none !important;
    position: static;
    transition: none;
    height: 45px;
    background-image: url("/build/images/tutos-videos.webp");
    background-position-y: bottom;
    background-position-x: 25px;
    background-repeat: no-repeat;
    background-size: 200px;
    filter: opacity(0.7);
    transform: translate(0px, 0px);
    transition: filter 0.5s, transform 0.5s;
}
.tutoLink:hover {
filter: opacity(1);
transform: translate(0px, -2px);
transition: filter 0.5s, transform 0.5s;
}

.registrationForm {
    padding:30px 0 0 0 ;
}




.list-group {
    position: relative;
    background-color: $bleu-4!important;
    border-radius: 0 !important;
    width: 280px;
    min-width: 280px!important;
}


.active {
    background-color:$vert-7!important;
    border: none !important;
    background: no-repeat url("/build/images/angle-menu-backoff.svg");
    background-position: center right;
}

.active-parentmenu {
    background-color:$bleu-5!important;
    border: none !important;
    background: no-repeat url("/build/images/angle-menu-backoff.svg");
    background-position: center right;
}


.tableevent {
    background-color: #F7F7F2;
    border-radius: 8px;
    padding: 40px;
    width: 100%;
    margin-bottom: 1rem;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    color: #444444; }

.tableevent th, .tableevent td {
      padding: 10px 10px;
      vertical-align: top;
      border: 0px; }

.tableevent thead th {
      vertical-align: bottom;
      border-bottom: 2px solid #dee2e6; }

.tableevent img {
      border-radius: 4px; }

.tableevent tr:nth-child(odd) {
      background: #EAEBDE; }

.tableevent th td:nth-child(1) {
    color: red;
    padding: 0 10px 0 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #444444; }

.user-row {
    display: flex;
    flex-wrap: wrap;
    margin:0 0 40px 0;
}

// PERONNALISATION DES FILTRES CHECKLIST POUR TABLEFILTER :
/*couleur du texte une fois coché*/
.flt_checklist_slc_item label {
    // color: #000!important;
    color: black !important;
  }
.div_checklist li.flt_checklist_item:hover label{
background-color: $bleu-3 !important;
}

.div_checklist li.flt_checklist_item {
    padding: 0 !important;
    margin: 0 !important;
    border-radius: 4px!important;
    background-color:$vert-1!important;
    border: 0px!important;
}
/*recherche par*/
.div_checklist {
    width: 100%;
    height: 90px;
    line-height: 30px;
    border: 0px!important;
    border-radius: 4px!important;
    margin-top: 17px!important;
    padding-left: 10px!important;
    background-color:$vert-1!important;
}

.select2-results__options{
    max-height: 400px !important;
}