.bigOne {
    padding: 0 !important;
}

.bandeNoir {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    background-color:$gris-2;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    align-items: center ;
    a {
        color:$vert-2;
        text-decoration: none;
        width: auto;
    }
    :hover {
        text-decoration: none;
        color:white;
    }
}


.what{
    padding-left:20px;
}


.intervenant {
    border-left: 1px solid #707070;
    padding-left:20px;
    padding-right:25px;
    img {
    width: 16px;
    margin-right: 5px;
    }
    p {
    color:$vert-2;
    padding-top:15px;
    }
    :hover {
        color:white;
    }
}
/*

.bandeauHeader {
    width: 100vw;
    max-width: 100%;
    height: 15vw;
}
*/

.inscription:hover {
    border-style: none !important;
}

@media (min-width: 992px){

    .menuNav {
        float: right;
        position: absolute;
        flex-direction: column;
    }

    .texteArea {
        border-radius: 4%;
        min-height: 6vh !important;
        background-color:transparent !important;
        border: white solid 1px;
        color: white !important;
        width: 10vw;
    }

    .texteArea::placeholder {
        color: white !important;
    }

    .formSearch {
        position: relative;
        right: 0;
        background-color: transparent !important;
        border: none !important;
        margin-left: 0.5rem;
    }
}

@media only screen and (max-width: 991px) {
/*
    .burger {
        position: absolute;
        right: 1vw;
        top: 5vw;
        background: green !important;
    }
*/
/*
    #navbarSupportedContent {
        background-color: #eaebde;
        font-size: 3vw;
        height: auto;
    }
*/
/*
    .myAccount, .inscription {
        border: none !important;
        background: transparent !important;
    }
*/

    .bandeNoir a, .intervenant p {
        width: auto;
    }

    .bandeNoir img {
        width: 16px;
    }

    .intervenant {
        padding-left:10px;
        padding-right:5px;
        p {
        padding-top:15px;
        }
    }
    .what{
        padding-left:5px;
    }
}

@media only screen and (max-width: 650px) {
    .bandeNoir a {
        width: auto;
    }
}

/*#################### VINCENT ######################*/
.logoHeader {
    width: 148px;
    display: block;
    margin: 35px 0 0 30px;
    position: absolute;
}

.bandeau-an {
    width: 100vw;
    height: 120px;
    background-image: url("/build/images/bandeau-4000.jpg");
    background-size: cover;
    background-position-y: bottom;
    background-repeat: no-repeat;
    }


#navbarSupportedContent {
    right: 35px !important;
    height: 140px;
    justify-content: center;
    top: 0px;
}

@media (min-width: 992px){

    .myAccount {
    border-radius: 4px;
    background-color: $vert-7 !important;
    min-width: 215px !important;
    min-height: 40px !important;
    border: 1px solid $white;
    @include taille-paragraphe($white,300); 
    text-transform: uppercase; 
}

.inscription {
    text-decoration: none;
    color: white;
    min-width: 215px !important;
    min-height: 40px !important;
    background: transparent;
    border-style: none !important;
    @include taille-h4($white);
}
}


@media only screen and (max-width: 991px) {

    #navbarSupportedContent {
    background-color: $vert-2;
    height: 34px;
    margin: none;
    text-align: right;
     }

.myAccount {
    border:none;
    @include taille-paragraphe($vert-8,400); 
    text-transform: uppercase; 
    text-align: center;
    background: transparent;
    margin-right: 10px;;
    }

.inscription {
    display: inline-flex;
    text-align: center;
    border-left:1px solid $vert-12;
    border-bottom: none;
    border-top:none;
    border-right:none;
    background: transparent;
    @include taille-paragraphe($vert-8,400); 
    }


.burger {
    position: absolute;
    right: 10px;
    top: 10px;
    background:$vert-7 !important;
        }

.bandeau-an {
    width: 100vw;
    height: 75px;
    background-image: url("/build/images/bandeau-1200.jpg");
    background-size: 200%;
    background-position: -60px -30px;
    }
.logoHeader {
    width: 112px;
    margin: 10px 0 0 12px;
    }
}