// This files defines styles overrides for the frontend calendar view.

// General rules to write styles here
// 1- use :root variable if it exists in fullcalendar (see https://github.com/fullcalendar/fullcalendar/blob/main/packages/core/src/styles/vars.css to view full list)
// 2- try a css selector / attribute
// 3- add !important to this rule if it doesn't work.

// FULLCALENDAR variables
:root {
    --fc-border-color: #EAEBDE;
    --fc-list-event-hover-bg-color: #e3ead2;
    --fc-button-text-color: #fff;
    --fc-button-bg-color: #4E6626;
    --fc-button-border-color: #4E6626;
    --fc-button-hover-bg-color: #678732;
    --fc-button-hover-border-color: #678732;
    --fc-button-active-bg-color: #7DA33D;
    --fc-button-active-border-color: #7DA33D;
}

@media only screen and (min-width: 1300px){
    .calendar{
        margin-top: 10px;
        margin-bottom: 4px;
    }
}
@media only screen and (max-width: 1300px){
    .calendar{
        margin-top: 50px;
        margin-bottom: 4px;
    }
}

.calendarContainer {
    overflow: visible; // for multiple events popup on the right of the calendar.
    color: rgba($vert-8, 1);
    min-height: 600px;
    .fc-event{
        border-radius: 4px;
        height: 20px;
    }

    .fc-list-event-dot{
        display: None;
    }

    img.cat-image {
        width: 1em;

        &.cat-image-month-view {
            margin-left : 2px;
            margin-right : 5px;
        }
    }

    div.event-list-circle {
        height : 25px;
        width : 25px;
        border-radius : 4px;
        display : flex;
        justify-content : center;
        align-items : center;
    }

    .fc-event-title {
        text-overflow: ellipsis;
        font-size: 12px;
    }
    
    .fc-list {
        margin-bottom: 40px;
        border: 1px solid;
        border-color: rgba($vert-3, 1);
        border-radius: 4px;
        background-color: #ffffff !important;
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
        color: rgba($gris-2, 1);
    }
    .fc-list-table td{
        vertical-align: middle;
    }
    .fc-list-empty{
        background-color: #ffffff !important;
        background-image:url("/build/images/no-result-inscription.svg");
        background-size: 60%;
        border-radius: 4px;
        align-items: flex-start;
        padding: 45px;
        background-repeat: no-repeat;
        @include taille-h4($gris-1);
    }

    @media screen and (max-width: 620px){
        .fc-list-empty{
            height: 50vh;
            background-position: 50% 180px;
        }
        .fc-list-empty .fc-list-empty-cushion {
            margin: 0em 0px;
        }
    }

    @media only screen and (min-width:621px) and (max-width:1500px){
        .fc-list-empty{
            height: 50vh;
            background-position: 50% 80px;
        }
        .fc-list-empty .fc-list-empty-cushion {
            margin: 0em 0px;
        }
    }
    @media only screen and (min-width:1501px) and (max-width:5120px){
        .fc-list-empty{
            height: 50vh;

            background-position: 50% 80px;
            background-size: 40%;
        }
        .fc-list-empty .fc-list-empty-cushion {
            margin: 0em 0px;
        }
    }
    
    .fc-list-day-cushion {
        background-color: rgba($vert-1, 1);
    }

    .fc-event-time {
        flex-shrink:0;
        font-size: 12px;
    }
    .fc-theme-standard td{
        height: 100%;
    }
    // liens over vue calendrier
    // ----------------------------------------------------------------------------------------------------
    a.fc-event {
        &:hover { 
            filter: brightness(110%);
            }
    }
    
    @media only screen and (min-width: 1300px){
        .fc-toolbar.fc-header-toolbar {
            margin-bottom: 30px;
            }
    }
    @media only screen and (max-width: 1300px){
        .fc-toolbar.fc-header-toolbar {
            margin-bottom: 10px;
            margin-top: 10px;
            }
    }
  
    .fc.fc-daygrid-day-frame{
        height: 100px;
    }
        // les chiffres du mois
    // ----------------------------------------------------------------------------------------------------
    .fc-daygrid-day-number {
        padding: 2px 0 0 4px;
        line-height: 1;
        font-size: 11px;
        color: rgba($gris-2, 1);
    }
    // alignement à gauche des dates vue calendrier
    // ----------------------------------------------------------------------------------------------------
    .fc-daygrid-day-top {
        justify-content: flex-end;
    }
    // Custom CSS (use !important if necessary)
    .fc-day{
        background-color:#ffffff;
    }
    .fc-button{
        padding: 0.1em 0.45em;        
    }
    .fc-button:focus {
        outline: 0;
        box-shadow: 0 0 0 0 rgba(255,255,255, 0)!important;
    }
    .fc-toolbar {
        /*justify-content: flex-end;*/
        justify-content: end;
    flex-direction: row-reverse;
    }
    .fc-toolbar-title {
        margin: 0 10px 0 10px;
        min-width: 150px;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
    }
    .fc-col-header-cell-cushion {
        padding: 2px 0 0 4px;
        line-height: 0.8;
        font-size: 13px;
        font-weight: 400;
    }

    .fc-day-today {
        background-color:rgba($bleu-2, 1)!important;
    }

    .fc-day-today th div {
        background-color:rgba($bleu-2, 1);
    }
 
    .fc-daygrid-event.cancelled, .fc-daygrid-event.cancelled .fc-event-title{
        text-decoration:line-through;
        color: white;
    }
    .fc-list-event.cancelled{
        text-decoration:line-through;
    }

    .fc-event-title-container.cancelled {
    text-decoration:line-through;
      }
        
    @media only screen and (max-width: 992px) {
        .fc-toolbar {
            flex-direction: column-reverse;
        }
    }

    // This will avoid the list view to show a local scrollbar.
    .fc-list {
        height: fit-content;
        position: initial !important;
        .fc-scroller {
            overflow: visible !important;
        }
    }
}
