.modal-lg {
    max-width: 1024px !important;
}

.col-detail-tableau {
    flex: 0 0 50%;
    padding-top: 40px;
}

.col-detail-categorie {
    flex: 0 0 80px;
    height:250px;
}
.detail-header {
    display: flex;
    margin-right: -15px;
    margin-left: -15px;
    flex-wrap: nowrap;
}

.detail-date{
    p {
            color:$vert-7;
            font-weight: 500;
            font-size: 23px;
        }
    }

.detail-titre{
@include taille-h1($gris-1,500);
text-transform: uppercase;
height: auto;
margin-right: 15px;
}

.detail-localisation{
    font-size: initial;
    font-weight: initial;
    text-transform: initial;
}

.titre-detail-descriptif{
    @include taille-h6($gris-1,500);
    text-transform: uppercase;
    padding-bottom: 20px;
    }

 .detail-para-descriptif{
    @include taille-paragraphe($gris-1,300);  
    text-align: justify;
    margin-bottom: 35px;
    }


.modal-header{
    border-bottom : 0px;
}

.modalTable {
    background: $vert-2;
}

.modalTable td:nth-child(even) {
    text-align: right;
    padding-right: 10px;
}

.modalTable td:nth-child(odd) {
    padding-left: 10px;
}

/*Ligne paire du tableau*/
.modalTable tr:nth-child(even){
    background:$vert-1;
    }

/* nombre de place dans le tableau*/
.tableImportant {
    background:$vert-7!important;
    td{color: $white!important;}
    }

.ligne-2bouts-foot {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    column-gap: 10px;
    margin:15px 0;
    }



@media (min-width: 768px){
.detailimage {
    flex: 0 0 40%;
    max-width: 49%;
    background-position: center;
    background-size: cover;
    max-height:250px;
}

.detaildescriptif {
    width: 50%;
    padding: 40px 44px 0px 60px;
    
}

.detail-ligne-descriptif {
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    tfoot {
        background-color: rgba($marron-4, 1)!important;
    }
}

.detail-info {
    padding-left: 30px;
    width: 55%;
}

.detail-icons{
    padding: 10px 0px 0px 10px;
    max-width: 30px;
    }
}

.col-detail {
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left:2vw;
    flex-grow: 1;
}
.ligne1-2bouts {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    column-gap: 10px!important;
    margin-right:15px;
}

@media only screen and (max-width: 768px) {
    .detailimage {
        flex: 1 0 86%;
        max-width: 90%;
        background-position: center;
        background-size: cover;
    }

    .col-detail-categorie {
        flex: 0 0 10vw;
        max-width: 100%;
        height:170px;
    }
    .modal-lg {
        max-width: 95vw !important;
    }
    .ligne1-2bouts {
    margin-top: 8px;
    margin-bottom: 20px;
    column-gap: 10px!important;
    margin-right:15px;
     }
    .detail-icons{
    padding: 5px 0px 0px 5px;
    max-width: 25px;
    }
    .detail-date{
        padding-top:10px;
    }
    .detail-titre{
        height: auto;
        }
    .detail-header {
        flex-wrap: wrap;
        }
}

@media only screen and (min-width: 768px) and (max-width: 991px){
    .col-detail {
        max-width: 44%;
    }
}
    

@media only screen and (max-width: 768px) {
.col-detail {
    max-width: 100%;
}
}


/* -------- SINGLE PAGE EVENEMENT ---------- */
div.single-page {

    .modal-header {
        display: none;
    }
    .col-detail {
        margin-top: 20px;
    }

    @media (min-width: 768px){
    padding: 40px;
    
    .col-detail-categorie {
        flex: 0 0 80px;
        height: 350px;
    }

    .detailimage{
    max-height: 350px;
    }

        }
}