#email-preferences-grid{
    display: grid;
    grid-template-columns: auto 200px 200px;
    width: 100%;
    margin-bottom: 30px;
    color: $gris-1;
    .grid-row{
        display: contents;
    }
    .grid-row {
        > div {
            padding:5px;
        }
    }
    .grid-row.color-alternance {
        > div {
            background-color: transparent;
            border-top: 1px dashed $vert-4;
        }
    }
    .grid-row.color-alternance:nth-of-type(2n) {
        > div {
            background-color: transparent;
            border-top: 1px dashed $vert-4;
        }
    }
    .grid-row.hover-highlight:hover{
        > div {
            background-color: $vert-2;
            color: $vert-8;
        }
    }
    .cell-header{
        text-align: center;
        border-bottom: 1px solid $vert-5;
        padding-bottom: 10px;
    }
    .cell-content{
        text-align: center;
    }
    .cell-row-separator{
        height: 10px;
        border-bottom: 4px solid $vert-2;
        background-color: transparent;
    }
    .cell-subtitle{
        margin-left: 40px;
    }
}
.form-check-input {
    accent-color: $vert-8;
    width: 15px;
    height: 15px;
    border-radius: 4px;
}
