@import "~bootstrap/scss/bootstrap";
@import "vars.scss";
@import "back.scss";
@import "footer.scss";
@import "header.scss";
@import "index.scss";
@import "event.scss";
@import "structure.scss";
@import "login.scss";
@import "register.scss";
@import "users.scss";
@import "participation.scss";
@import "sortienature.scss";
@import "detail.scss";
@import "filtermenu.scss";
@import "ui-autocomplete.scss";
@import "myaccount.scss";
@import "calendar.scss";
@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&display=swap');

* {
    font-family: 'Roboto', sans-serif;
}


html, body {
    box-sizing: border-box;
    height: 100%;
    margin: 0;
    padding: 0;
}

// Show the scrollbar above the content, hence avoiding contentjumps.
html {
    overflow-x: hidden;
    margin-right: calc(-1 * (100vw - 100%));
}

body {
    display: flex;
    flex-direction: column;
    background-color:$vert-2;
}

.hidden {
    display: none;
}

@media only screen and (max-width: 900px) {
.hidden-mobile {
    display: none;
}
}

.toHide {
    display: none;
}

[class*=hint--]:after{
    letter-spacing: 1px;
}
