/*########## BLOCK DEVENIR ##########*/
.roleblock {
    padding: 20px 60px;
    background-color: $marron-2;
    margin-top: 4px;
    border-radius: 4px;
}

/*######### Provisoir ##########*/
.formStruc {
    color: $gris-2;
}

/*######### Général ##########*/
body {
    font-family: 'Roboto', sans-serif;
}

ul {
    list-style: none;
}

.front_all {
    padding-right: 0 !important;
    padding-left: 0 !important;
    height: auto;
    display: flex;
    justify-content: space-between;
    flex-basis:100%;
}

/*######### Menu page carte et page liste ##########*/
.front_menu {
    z-index: 1;
    position: absolute;
    max-height: 71px;
}

.btnMenuBig:hover {
    background-color: $vert-8 !important;
}
.btnMenuBig[selected] {
        background-color: $vert-7 !important;
}

.btnMenuBig {
    width: 150px;
    height: 42px;
    border-radius: 0 0 4px 4px!important;
    font-size: 15px!important;
    font-weight: 500!important;
    color:$vert-2!important;
    background-color: $vert-9 !important;
    margin: 0 4px 0 0;
}
.btnMenuBig img {
    max-width: 20px;
}

/*######### fin Menu page carte et page liste ##########*/
/*######### menu filtre liste ##########*/
.front_filter {
    z-index: 1;
    width: 280px;
    min-width: 280px;
    position: relative;
}

.icon {
    width: 3vw;
    margin-right: 5px;
}

.filterNav {
    padding-top: 0;
    padding-bottom: 0;
}

.list-filter {
    width: 20vw;
}


.filterBtnA a h4{
    @include taille-h4($white);
}


.filterDiv a {
    color: #000000;
}
.filterDiv a:hover,
.filterDiv a:active {
    color: #4E6626 !important;
    text-decoration: none;
}

.card-header{
    display: inline-flex;
}

.filterA,
.filterBtnA a {
    color: rgba(255, 255, 255, 1);
    text-decoration: none !important;
}
.filterAllUl a:hover,
.filterBtnA a:hover {
    color: rgba(255, 255, 255, 1);
}
/*######### fin menu filtre  liste ##########*/

/*######### Debut Mise En Page de ce qu'affiche les markers  #########*/
.gm-style .gm-style-iw-c {
    padding: 0;
    border-radius: 4px;
   /* overflow: visible !important;*/
}

.gm-ui-hover-effect{
    display:none !important;
}

.markerInfo {
    display: flex;
    align-content: center;
}

.markerImage {
    background-color: red;
    height: auto;
    display: flex;
    justify-content: center;
}



.markerTitle {
    text-transform: uppercase;
    font-weight: bolder;
}

.markerSep {
    display: flex;
    justify-content: space-between;
}


@media only screen and (max-width: 767px)  {
    .markerSep {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
       
    }
    .markerSep>div+div {
        align-self: flex-start!important;
    }
}

.listContainer::-webkit-scrollbar {
    display:none;
}

.colorsCategory {
    max-width:40px !important;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: blue;
}

/*
.eventCard {
    min-height: 25vh;
}
*/


.eventInfosBottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-content: center;
}

.eventInfosBottom2 {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: center;
}


/*
.firstBottomEvent {
    margin-top:20px;
    line-height: 14px;
}
*/
.secondBottomEvent {
    display: flex;
    justify-content: space-around;
    align-items: center;
    white-space: nowrap;
}
.secondButton {
    margin-left: 5px;
}
.btn-dark {
    background-color: #83B2AD !important;
    border:none !important;
}

.picto {
    margin-left: 10px;
}

.picto-structure {
    max-width: 350px;
    height: 85px;
}

.picto-structure-small {
    max-width: 34px;
    height: auto;
    margin:10px 3px 0px 3px!important;
    border-radius:4px;
    }

.markerImage img.picto-structure-small-carte {
    min-width: 34px!important;
    margin:8px 3px 0px 3px!important;
    border-radius:4px;
    max-width: 34px!important;
    max-height: 100%!important;
    }
    

.markerSep>div+div {
    align-self: center;
}

.markerBtn {
    background-color: #7da33d !important;
    color: white !important;
}

.newColor {
    color: #7DA33D;
}
/*######### Couleurs de classe pour les cards ##########*/
// NOTE: use variables here as we import them in calendar.js
$agricultureColor: #CC798E;
$amenagementColor: #8E7C67;
$eauColor: #5AA0B5;
$climatColor: #786AA7;
$fauneColor: #83A84E;
$santeColor: #D34A4A;
$enjeuColor: #DFBB3B;
$nothemeColor: none;
:export {
    agricultureColor: $agricultureColor;
    amenagementColor: $amenagementColor;
    eauColor: $eauColor;
    climatColor: $climatColor;
    fauneColor: $fauneColor;
    santeColor: $santeColor;
    enjeuColor: $enjeuColor;
    nothemeColor: $nothemeColor;
}
.agriculture {
    background: $agricultureColor;
}

.amenagement {
    background: $amenagementColor;
}

.eau {
    background: $eauColor;
}

.climat {
    background: $climatColor;
}

.faune {
    background: $fauneColor;
}

.sante {
    background: $santeColor;
}

.enjeu {
    background: $enjeuColor;
}

.notheme {
    background:$nothemeColor;
    background-image: url("/build/images/background-notheme.svg");
    background-repeat: repeat;
}

/*
.modal-lg {
    max-width: 80vw !important;
}
*/

.modal-lg h3 {
    font-weight: bolder;
}

.modalBtn {
    background: #83B2AD !important;
    color: white !important;
}

.modalDate {
    color: #7DA33D;
}

.modalTitle {
    text-transform: uppercase;
    @include taille-h1($gris-1,300);
}
/*
.modalTable tr {
    background: #EAEBDE;
}

.modalTable td:nth-child(even) {
    text-align: right;
}

.modalTable tr:nth-child(even){
    background: #BBC982;
}
*/

.modalImage {
    background-position: center;
}

.past, .past tr {
    background-color: lightgrey !important;
}

.past a, .past p {
    color: black;
}

/*
.filterAllUl {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
}
*/

.filtersticky{
position: -webkit-sticky;
position: sticky;
top: 0px;
}

@media only screen and (max-width: 991px) {


    .front_menu .btn-group {
        width: 100vw !important;
    }

    .btnMenuBig img, .filterAllUl img {
        display: none;
    }

   
    .front_filter {
            z-index: 1;
            min-width: 100%;
            position: relative;
            top: 47px;
    }

    .list, .calendar{
        margin-top: 50px!important;
        order:2;
    }
}

/*################### VINCENT ################*/
span.keyword {
    font-style: italic !important;
    border-bottom: 3px dotted $rouge-1 !important;

}

#map {
    z-index: 0;
    height: auto;
    width: 100%!important;
}

// Remove the blue border around the map on focus :
.gm-style iframe + div { border:none!important; }

.cluster{
    span {
    color:$white;
    font-size: 16px;;
}
}

.filterBtnTop, .filterBtnTopBottom {
    text-transform: capitalize;
    font-size: 2vh;
    height: 45px;
}

.filterBtnTop,
.filterBtnTopBottom {
    padding: 14px 0px 36px 13px;
    background-color: $vert-8;
 /*   border-bottom: 1px solid ($bleu-3);*/
    @include taille-h5($white,300);
    cursor: pointer;
    border-top: 1px solid rgba(255, 255, 255, 0.225);
}

.filterBtnTop[selected] {
    background-color: $vert-7;
}

// FK COMMENTED
// .filterBtnTop[selected] img{ // when selected, hide the default icon...
//     display:none;
// }
// .filterBtnTop[selected] img.selected{ // ... and show the yellow icon.
//     display:initial !important;
// }

#nb-of-events {
    background: $white;
    padding: 5px;
    @include taille-h6($marron-1,300); 
    border-radius: 4px;
    max-height:25px;
    margin: 10px auto 0 auto;
    width: 100px; 
    text-align: center;
}

.filter-number-selected{
    font-style: italic;
    font-size: 75%;
    color: #a6a6a6;
}

.iconButton {
    height:20px;
    margin-right:2px;
    padding-bottom: 2px;
}

.icons {
        max-width: 20px;
        margin-right: 5px;
}

.card-body {
    @include taille-paragraphe($gris-1,400);
    background-color: $vert-7;
    border-radius: 4px 4px 0 0;
 }

 .filterBtnA {
    background-color: $marron-1 !important;
    height: 40px;
    padding: 0.45rem 1.40rem;
}

.card-body hr{
    margin-top:6px;
    margin-bottom:6px;
}

.btnCheckboxIndex {
    margin: 1px 5px 0px -0.25vw;
}

.list, .calendar{
    margin-left:5%;
    margin-right:5%;
    width: 90%;
}
.list{
    margin-top: 70px;
    margin-bottom: 20px;
}


.listContainer, .calendarContainer {
    width:100%;
    height: auto;
    overflow: auto;
}

.contentList {
    padding: 0!important;
}

/*###########LISTE#############*/
.alignRow {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}


.eventDate {
    color: #7DA33D;
    display:flex;
    justify-content: space-between;
    align-items: center;
    p {
        @include taille-h5($vert-7,500);
    }
}

.icon-single-page-Event{ 
    height: 16px;
    margin: -2px 0 0 5px;
}

.structureEventLink{
    color: rgba($gris-1, 1);
    p {
        @include taille-h5($vert-7,300);
    }
    margin-left: 0px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    &:hover {
        color:rgba($vert-8, 1);
        text-decoration: none;
    }
}



.picto img {
    height: 13px;
    margin: 0 2px;
    vertical-align: 0px;;
}

.eventTitle {
    @include taille-h6($gris-1,500);
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    cursor: pointer;
    &:hover{
    /*background-color: rgba($vert-7, 0.2);
    border-radius: 4px;*/
   color:rgba($vert-8, 1);
    }
}

/*############ DETAIL SUR CARTE ##########*/

.gm-style-iw-d {
    overflow: auto !important;
}

.markerContent {
    display: flex;
    flex-direction: column;
    flex: 3 1 auto;
    text-align: left;
    text-overflow: ellipsis;
    margin-left:15px;
}

.markerContent p {
        // overflow: hidden; Commented this line to avoid hint.css to showup behind the event image.
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 5px;;
}

.markerImage img {
    max-width: 20px;
    margin: 10px 10px;
    max-height: 20px;
}



/*décalage de "plan et satellite" de la carte vers le bas pour ne pas être masqué*/

#map .gm-fullscreen-control {
    margin: 10px 10px 0px 0px !important;
}
#map .gm-style-mtc {
    margin: 60px 0px 0px 10px !important;
}
/*
#map .gmnoprint .gm-bundled-control {
    right: 400px !important;
}
*/
#map .gm-control {
    margin: 0px !important;
}
  


/*########## MAX 991px #########*/
@media only screen and (max-width: 991px) {
    .front_all {
        flex-direction: column;
    }
    
    #map {
        top: 0vh;
        min-height: 100vh !important;
        width: 100% !important;
        order:2;
    }
    .markerContent {
        margin-left:0px;
    }

}

/*########## MIN 1680px #########*/
@media only screen and (min-width: 1680px) {
.listContainer {
    width:100%;
    height: auto;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    column-gap:2%;
}

.contentList {
    padding: 0!important;
    flex-basis: 49%;
}
}

/*##########MESSAGES################*/

.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 10px;
    border: 0;
    border-radius: 0;
    @include taille-paragraphe($marron-2,300);  
}

.alert-danger {
    color: $white;
    background-color: $rouge-1;
}


.alert-warning {
    color: $white;
    background-color: $orange-3;
}

.alert-error {
    color: $white;
    background-color: $rouge-1;
}

.alert-success {
    color: $white;
    background-color: $vert-7;
}


.center {
    margin: 0 auto;
}

/*cadre empty mosaic*/

@media (max-width: 1500px){
        .mosaic-empty{
            height: 50vh;
            background-color: #ffffff !important;
            background-image:url("/build/images/no-result-inscription.svg");
            background-position: 50% 80px;
            background-repeat: no-repeat;
            background-size: 60%;
            color: rgba($vert-8, 1);
            border-radius: 4px;
            align-items: flex-start;
        }
    }
    @media (min-width: 1500px){
        .mosaic-empty{
            height: 50vh;
            background-color: #ffffff !important;
            background-image:url("/build/images/no-result-inscription.svg");
            background-position: 50% 80px;
            background-repeat: no-repeat;
            background-size: 40%;
            color: rgba($vert-8, 1);
            border-radius: 4px;
            align-items: flex-start;
        }
    }